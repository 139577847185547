import React from 'react';
import rocketship from './img/rto.png';
import './css/App.css';

function App() {
  return (
    <div className="columns is-centered is-vcentered">
      <div className="column is-four-fifths">
        <div className="columns is-vcentered">
          <div className="column is-three-fifths">
            <div className="container">
              <img alt="rocketship" className="rocketship" src={rocketship} />
            </div>
          </div>
          <div className="column">
            <div className="container text-container-inner">
              <h1 className="main-title is-size-1-desktop is-size-3-mobile is-size-3-tablet">
                We're getting ready to take off!
            </h1>
              <h2 className="sub-title is-size-5-desktop is-size-5-mobile is-size-5-tablet">
                <span className="vtitle">Versaic by Benevity</span> is currently under maintenance.<br />
            Check back later.
            </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;

